const { GamePlatform } = require("./GamePlatform");
const { getSettingsFromURI } = require("./URIReader");

// RequestAnimFrame: a browser API for getting smooth animations
window.requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

const config = getSettingsFromURI(window.location.search);

let splashEnabled = false;

let splashScreen = document.getElementById("splash-screen");
if (splashScreen) {
  if (config.splashCoverCenter) {
    splashScreen.style.width = "100%";
    splashScreen.style.height = "100%";
    splashScreen.style.top = "0";
    splashScreen.style.left = "0";
    splashScreen.style.objectFit = "fill";
    splashScreen.style.objectPosition = "center";
    splashScreen.style.opacity = (config.splashOpacity / 100).toString();
  } else {
    splashScreen.style.width = window.innerWidth + "px";
    splashScreen.style.top = (window.innerHeight * config.splashY) / 100 + "px";
    splashScreen.style.opacity = (config.splashOpacity / 100).toString();

    splashScreen.style.transform =
      " translateY(-50%) scale(" + config.splashScale + "%)";
  }
  splashScreen.style.display = "block";
  splashScreen.src = config.path + "/" + config.splashImage;
}

const gamePlatform = new GamePlatform();

gamePlatform.init(
  {
    play: () => {
      splashEnabled = true;
      // playing = true;
    },
    restart: () => {
      reset();
      gamePlatform.sendScore(0, false);
      gamePlatform.ready();
    },
  },
  config,
  () => {
    setTimeout(() => {
      init();
    }, 50);
  }
);

var canvas = document.getElementById("canvas"),
  ctx = canvas.getContext("2d");

var width = Math.max(320, window.innerWidth),
  height = window.innerHeight;

canvas.width = width;
canvas.height = height;

document.ontouchstart = (e) => {
  e.preventDefault();
  e.stopPropagation();
};
document.ontouchmove = (e) => {
  e.preventDefault();
  e.stopPropagation();
};
document.ontouchend = (e) => {
  e.preventDefault();
  e.stopPropagation();
};
document.ontouchcancel = (e) => {
  e.preventDefault();
  e.stopPropagation();
};
document.oncontextmenu = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

//Variables for game
var platforms = [],
  image = document.getElementById("sprite"),
  player,
  platformCount = Math.floor(window.innerHeight / 57),
  position = 0,
  gravity = 0.2,
  animloop,
  flag = 0,
  broken = 0,
  dir,
  score = 0,
  lastScore = 0,
  firstRun = true,
  playing = false,
  jumpCount = 0;

var playerImage,
  playerImageFlipped,
  floorImage,
  platform1Image,
  platform2Image,
  platform3Image,
  platform4Image,
  springImage,
  springBounceImage;

var maxHeight = 0;

//Base object
var Base = function () {
  this.height = config.floorHeight;
  this.width = width;

  //Sprite clipping
  this.cx = 0;
  this.cy = 614;
  this.cwidth = 100;
  this.cheight = 5;

  this.moved = 0;

  this.x = 0;
  this.y = height - this.height;
  this.repeat = 1;

  this.draw = function () {
    try {
      if (!floorImage) {
        floorImage = document.getElementById(config.floorImage);
        this.height = config.floorHeight;
        this.width =
          (config.floorHeight / floorImage.height) * floorImage.naturalWidth;
        this.repeat = Math.ceil(width / this.width);
      }
      for (let i = 0; i < this.repeat; i++)
        ctx.drawImage(
          floorImage,
          this.x + i * (this.width - 1),
          this.y,
          this.width,
          this.height
        );
    } catch (e) {}
  };
};

//Player object
var Player = function () {
  this.vy = 11;
  this.vx = 0;

  this.isMovingLeft = false;
  this.isMovingRight = false;
  this.isDead = false;

  this.width = 80;
  this.height = 80;

  //Sprite clipping
  // this.cx = 0;
  // this.cy = 0;
  // this.cwidth = 80;
  // this.cheight = 100;

  this.dir = "right";

  this.x = width / 2 - this.width / 2;
  this.y = height;

  playerImage = document.getElementById(config.playerImage);
  this.width = config.playerWidth;
  this.height = (this.width / playerImage.width) * playerImage.height;

  //Function to draw it
  this.draw = function () {
    try {
      // if (this.dir == "right") this.cy = 121;
      // else if (this.dir == "left") this.cy = 201;

      if (playerImage === undefined) {
        playerImage = document.getElementById(config.playerImage);
      }
      if (playerImageFlipped === undefined) {
        playerImageFlipped = document.getElementById(
          config.playerImage + "_flipped"
        );
      }

      // this.cy = 121;

      ctx.drawImage(
        this.dir == config.playerDirection || !config.playerFlip
          ? playerImage
          : playerImageFlipped,
        this.x,
        this.y,
        this.width,
        this.height
      );
    } catch (e) {}
  };

  this.jump = function () {
    this.vy = -8;
  };

  this.jumpHigh = function () {
    this.vy = -16;
  };
};

//Platform class

function Platform() {
  this.width = 70;
  this.height = 17;

  this.x = Math.random() * (width - this.width);
  this.y = position;

  position += height / platformCount;

  if (score == 0) this.y -= base.height;

  this.flag = 0;
  this.state = 0;

  //Sprite clipping
  this.cx = 0;
  this.cy = 0;
  this.cwidth = 105;
  this.cheight = 31;

  //Function to draw it
  this.draw = function () {
    try {
      let theWidth;
      if (this.type == 1) {
        this.image = document.getElementById(config.platform1Image);
        theWidth = config.platform1Width;
      } else if (this.type == 2) {
        this.image = document.getElementById(config.platform2Image);
        theWidth = config.platform2Width;
      } else if (this.type == 3 && this.flag === 0) {
        this.image = document.getElementById(config.platform3Image);
        theWidth = config.platform3Width;
      } else if (this.type == 3 && this.flag == 1) this.image = undefined;
      else if (this.type == 4 && this.state === 0) {
        this.image = document.getElementById(config.platform4Image);
        theWidth = config.platform4Width;
      } else if (this.type == 4 && this.state == 1) {
        this.image = undefined;
      }

      // if (this.image === undefined) return;
      const { width, height } = scaleImage(this.image, theWidth);
      this.width = width;
      this.height = height;

      if (this.image === undefined) return;

      ctx.drawImage(
        this.image,
        // this.cx,
        // this.cy,
        // this.cwidth,
        // this.cheight,
        this.x,
        this.y,
        this.width,
        this.height
      );
    } catch (e) {}
  };

  //Platform types
  //1: Normal
  //2: Moving
  //3: Breakable (Go through)
  //4: Vanishable
  //Setting the probability of which type of platforms should be shown at what score
  if (score >= 5000) this.types = [2, 3, 3, 3, 4, 4, 4, 4];
  else if (score >= 2000 && score < 5000)
    this.types = [2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4];
  else if (score >= 1000 && score < 2000) this.types = [2, 2, 2, 3, 3, 3, 3, 3];
  else if (score >= 500 && score < 1000)
    this.types = [1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3];
  else if (score >= 100 && score < 500) this.types = [1, 1, 1, 1, 2, 2];
  else this.types = [1];

  this.type = this.types[Math.floor(Math.random() * this.types.length)];

  //We can't have two consecutive breakable platforms otherwise it will be impossible to reach another platform sometimes!
  if (this.type == 3 && broken < 1) {
    broken++;
  } else if (this.type == 3 && broken >= 1) {
    this.type = 1;
    broken = 0;
  }

  this.moved = 0;
  this.vx = 1;
}

// for (var i = 0; i < platformCount; i++) {
//   platforms.push(new Platform());
// }

//Broken platform object
var Platform_broken_substitute = function () {
  this.image = document.getElementById(config.platformBreakImage);

  const { width, _h } = scaleImage(
    document.getElementById(config.platform3Image),
    config.platform3Width
  );

  const { _w, height } = scaleImage(this.image, width);

  this.height = height;
  this.width = width;

  this.x = 0;
  this.y = 0;

  this.appearance = false;

  this.draw = function () {
    try {
      if (this.appearance === true)
        ctx.drawImage(this.image, this.x, this.y, this.width, this.height);
      else return;
    } catch (e) {}
  };
};

var platform_broken_substitute;

//Spring Class
var spring = function () {
  this.x = 0;
  this.y = 0;

  const { width, height } = scaleImage(
    document.getElementById(config.springImage),
    config.springWidth
  );

  this.width = width;
  this.height = height;

  //Sprite clipping
  this.cx = 0;
  this.cy = 0;
  this.cwidth = 45;
  this.cheight = 53;

  this.state = 0;

  this.image = document.getElementById(config.springImage);

  this.setState = function (state) {
    if (state === 1) {
      const img = document.getElementById(config.springBounceImage);
      this.image = img;
      const scale = scaleImage(
        img,
        config.springBounceWidth
          ? config.springBounceWidth
          : config.springWidth,
        true
      );
      this.width = scale.width;
      this.height = scale.height;
    }
    if (state === 0) {
      const img = document.getElementById(config.springImage);
      this.image = img;

      const scale = scaleImage(img, config.springWidth, true);
      // console.log(w, h);

      this.width = scale.width;
      this.height = scale.height;
    }
    this.state = state;
  };

  this.draw = function () {
    var p = platforms[0];

    try {
      ctx.drawImage(
        this.image,
        this.x,
        this.y - this.height + p.height + 10,
        this.width,
        this.height
      );
    } catch (e) {}
  };
};
var player;
var Spring;
var base;

function init() {
  //Variables for the game
  var dir = config.playerDirection;
  jumpCount = 0;

  firstRun = false;

  base = new Base();

  player = new Player();
  reset();
  //Function for clearing canvas in each consecutive frame

  function paintCanvas() {
    ctx.clearRect(0, 0, width, height);
  }

  //Player related calculations and functions

  function playerMove(direction) {
    if (direction === "left") {
      dir = "left";
      player.isMovingLeft = true;
      player.isMovingRight = false;
    } else if (direction === "right") {
      dir = "right";
      player.isMovingRight = true;
      player.isMovingLeft = false;
    } else {
      player.isMovingRight = false;
      player.isMovingLeft = false;
    }
  }

  document.getElementById("move-left").onpointerdown = (e) => {
    playerMove("left");
  };
  document.getElementById("move-right").onpointerdown = (e) => {
    playerMove("right");
  };
  document.getElementById("move-left").onpointerup = (e) => {
    playerMove("");
    e.preventDefault();
    e.stopPropagation();
  };
  document.getElementById("move-right").onpointerup = (e) => {
    playerMove("");
    e.preventDefault();
    e.stopPropagation();
  };

  document.onpointerdown = (e) => {
    if (!playing && splashEnabled) {
      playing = true;
      splashEnabled = false;
      gamePlatform.gamestarted();

      document.getElementById("splash-screen").style.display = "none";
    }
    var isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobile) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  document.onpointerup = (e) => {
    playerMove("");
    e.preventDefault();
    e.stopPropagation();
  };
  document.onpointerleave = (e) => {
    playerMove("");
    e.preventDefault();
    e.stopPropagation();
  };

  function playerCalc() {
    if (dir == "left") {
      player.dir = "left";
      // if (player.vy < -7 && player.vy > -15) player.dir = "left_land";
    } else if (dir == "right") {
      player.dir = "right";
      // if (player.vy < -7 && player.vy > -15) player.dir = "right_land";
    }

    //Accelerations produces when the user hold the keys
    if (player.isMovingLeft === true) {
      player.x += player.vx * (width / 320);
      player.vx -= 0.15;
    } else {
      player.x += player.vx;
      if (player.vx < 0) player.vx += 0.1;
    }

    if (player.isMovingRight === true) {
      player.x += player.vx * (width / 320);
      player.vx += 0.15;
    } else {
      player.x += player.vx;
      if (player.vx > 0) player.vx -= 0.1;
    }

    // player.x *= window.innerWidth / 320;

    // Speed limits!
    if (player.vx > 8) player.vx = 8;
    else if (player.vx < -8) player.vx = -8;

    if (Math.abs(player.vx) < 0.1) player.vx = 0;

    //Jump the player when it hits the base
    if (player.y + player.height > base.y && base.y < height) player.jump();

    //Gameover if it hits the bottom
    if (
      base.y > height &&
      player.y + player.height > height &&
      player.isDead != "lol"
    )
      player.isDead = true;

    //Make the player move through walls
    if (player.x > width) player.x = 0 - player.width;
    else if (player.x < 0 - player.width) player.x = width;

    //Movement of player affected by gravity
    if (player.y >= height / 2 - player.height / 2) {
      player.y += player.vy;
      player.vy += gravity;
    }

    //When the player reaches half height, move the platforms to create the illusion of scrolling and recreate the platforms that are out of viewport...
    else {
      platforms.forEach(function (p, i) {
        if (player.vy < 0) {
          p.y -= player.vy;
        }

        if (p.y > height) {
          platforms[i] = new Platform();
          platforms[i].y = p.y - height;
        }
      });

      base.y -= player.vy;
      player.vy += gravity;

      if (player.vy >= 0) {
        player.y += player.vy;
        player.vy += gravity;
      }

      score++;
    }

    //Make the player jump when it collides with platforms
    collides();

    if (player.isDead === true) gameOver();
  }

  //Spring algorithms

  function springCalc() {
    var s = Spring;
    var p = platforms[0];

    if (p.type == 1 || p.type == 2) {
      s.x = p.x + p.width / 2 - s.width / 2;
      s.y = p.y - p.height - 10;

      if (s.y > height / 1.1) s.setState(0);

      s.draw();
    } else {
      s.x = 0 - s.width;
      s.y = 0 - s.height;
    }
  }

  //Platform's horizontal movement (and falling) algo

  function platformCalc() {
    var subs = platform_broken_substitute;

    platforms.forEach(function (p, i) {
      if (p.type == 2) {
        if (p.x < 0 || p.x + p.width > width) p.vx *= -1;

        p.x += p.vx;
      }

      if (p.flag == 1 && subs.appearance === false && jumpCount === 0) {
        subs.x = p.x;
        subs.y = p.y;
        subs.appearance = true;

        jumpCount++;
      }

      p.draw();
    });

    if (subs.appearance === true) {
      subs.draw();
      subs.y += 8;
    }

    if (subs.y > height) subs.appearance = false;
  }

  function collides() {
    //Platforms
    platforms.forEach(function (p, i) {
      if (
        player.vy > 0 &&
        p.state === 0 &&
        player.x + 15 < p.x + p.width &&
        player.x + player.width - 15 > p.x &&
        player.y + player.height > p.y &&
        player.y + player.height < p.y + p.height
      ) {
        if (p.type == 3 && p.flag === 0) {
          p.flag = 1;
          jumpCount = 0;
          return;
        } else if (p.type == 4 && p.state === 0) {
          player.jump();
          p.state = 1;
        } else if (p.flag == 1) return;
        else {
          player.jump();
        }
      }
    });

    //Springs
    var s = Spring;
    if (
      player.vy > 0 &&
      s.state === 0 &&
      player.x + 15 < s.x + s.width &&
      player.x + player.width - 15 > s.x &&
      player.y + player.height > s.y &&
      player.y + player.height < s.y + s.height
    ) {
      s.setState(1);
      player.jumpHigh();
    }
  }

  function updateScore() {
    // var scoreText = document.getElementById("score");
    // scoreText.innerHTML = score;
    if (score !== lastScore) {
      gamePlatform.sendScore(score, true);
      lastScore = score;
    }
  }

  function gameOver() {
    platforms.forEach(function (p, i) {
      p.y -= 12;
    });

    if (player.y > height / 2 && flag === 0) {
      player.y -= 8;
      player.vy = 0;
    } else if (player.y < height / 2) flag = 1;
    else if (player.y + player.height > height) {
      showGoMenu();
      hideScore();
      player.isDead = "lol";

      setTimeout(() => {
        //   // reset();
        gamePlatform.gameover(score);
      }, 500);
    }
  }

  //Adding keyboard controls
  window.addEventListener(
    "keypresseerere qeqieoqinxq jkdwdaw dawd asdsad ",
    () => {
      console.log("key");
    }
  );
  window.onkeydown = (e) => {
    var key = e.keyCode;

    if (key == 37) {
      dir = "left";
      player.isMovingLeft = true;
    } else if (key == 39) {
      dir = "right";
      player.isMovingRight = true;
    }

    // if (key == 32) {
    //   if (firstRun === true) {
    //     init();
    //     firstRun = false;
    //   } else reset();
    // }
  };

  window.onkeyup = function (e) {
    var key = e.keyCode;

    if (key == 37) {
      dir = "left";
      player.isMovingLeft = false;
    } else if (key == 39) {
      dir = "right";
      player.isMovingRight = false;
    }
  };

  //Function to update everything

  function update() {
    paintCanvas();
    platformCalc();

    springCalc();

    if (playing) playerCalc();
    player.draw();

    base.draw();

    updateScore();

    if (!player.isMovingRight && !player.isMovingLeft) {
      const left = document.getElementById("move-left");
      const right = document.getElementById("move-right");

      let pos = ((player.x + player.width / 2) / window.innerWidth) * 100;

      pos = Math.max(25, pos);
      pos = Math.min(75, pos);
      left.style.width = pos + "%";
      right.style.width = 100 - pos + "%";
    }
  }

  animloop = function () {
    update();
    requestAnimFrame(animloop);
  };

  animloop();

  hideMenu();
  showScore();
}

function reset() {
  hideGoMenu();
  showScore();
  player.isDead = false;

  document.getElementById("splash-screen").style.display = "block";

  flag = 0;
  position = 0;
  score = 0;
  lastScore = 0;
  playing = false;

  base = new Base();

  playerImage = undefined;
  floorImage = undefined;
  player = new Player();
  Spring = new spring();
  platform_broken_substitute = new Platform_broken_substitute();

  platforms = [];
  for (var i = 0; i < platformCount; i++) {
    platforms.push(new Platform());
  }
}

//Hides the menu
function hideMenu() {}

//Shows the game over menu
function showGoMenu() {
  //   var menu = document.getElementById("gameOverMenu");
  //   menu.style.zIndex = 1;
  //   menu.style.visibility = "visible";
  //   var scoreText = document.getElementById("go_score");
  //   scoreText.innerHTML = "You scored " + score + " points!";
}

//Hides the game over menu
function hideGoMenu() {
  //   var menu = document.getElementById("gameOverMenu");
  //   menu.style.zIndex = -1;
  //   menu.style.visibility = "hidden";
}

//Show ScoreBoard
function showScore() {
  //   var menu = document.getElementById("scoreBoard");
  //   menu.style.zIndex = 1;
}

//Hide ScoreBoard
function hideScore() {
  //   var menu = document.getElementById("scoreBoard");
  //   menu.style.zIndex = -1;
}

function playerJump() {
  player.y += player.vy;
  player.vy += gravity;

  if (
    player.vy > 0 &&
    player.x + 15 < 260 &&
    player.x + player.width - 15 > 155 &&
    player.y + player.height > 475 &&
    player.y + player.height < 500
  )
    player.jump();

  if (dir == "left") {
    player.dir = "left";
    if (player.vy < -7 && player.vy > -15) player.dir = "left_land";
  } else if (dir == "right") {
    player.dir = "right";
    if (player.vy < -7 && player.vy > -15) player.dir = "right_land";
  }

  //Accelerations produces when the user hold the keys
  if (player.isMovingLeft === true) {
    player.x += player.vx;
    player.vx -= 0.15;
  } else {
    player.x += player.vx;
    if (player.vx < 0) player.vx += 0.1;
  }

  if (player.isMovingRight === true) {
    player.x += player.vx;
    player.vx += 0.15;
  } else {
    player.x += player.vx;
    if (player.vx > 0) player.vx -= 0.1;
  }
  console.log(player.vx);

  if (Math.abs(player.vx) < 0.1) player.vx = 0;

  //Jump the player when it hits the base
  if (player.y + player.height > base.y && base.y < height) player.jump();

  //Make the player move through walls
  if (player.x > width) player.x = 0 - player.width;
  else if (player.x < 0 - player.width) player.x = width;

  player.draw();
}

function update() {
  ctx.clearRect(0, 0, width, height);
  playerJump();
}

// menuLoop = function () {
//   update();
//   requestAnimFrame(menuLoop);
// };

function scaleImage(image, width, print) {
  if (!image) return { width: 0, height: 0 };
  if (print) console.log(image.naturalHeight, image.naturalWidth, width);
  const aspectRatio = image.naturalWidth / image.naturalHeight;
  const height = width / aspectRatio;
  return { width, height };
}
