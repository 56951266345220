import { GifReader } from "omggif";

export const loadGifFrameList = async (gifUrl) => {
  const response = await fetch(gifUrl);
  const blob = await response.blob();
  const arrayBuffer = await blob.arrayBuffer();
  const intArray = new Uint8Array(arrayBuffer);

  const reader = new GifReader(intArray);

  const info = reader.frameInfo(0);

  return new Array(reader.numFrames()).fill(0).map((_, k) => {
    const image = new ImageData(info.width, info.height);

    reader.decodeAndBlitFrameRGBA(k, image.data);

    return image;
  });
};

export const flipSprite = (img) => {
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  const ctx = canvas.getContext("2d");

  ctx.translate(img.width, 0);
  ctx.scale(-1, 1);
  ctx.drawImage(img, 0, 0);

  const image = new Image();
  image.src = canvas.toDataURL();
  image.id = img.id + "_flipped";

  document.body.appendChild(image);
};

export const createSpriteSheet = (images) => {
  const canvas = document.createElement("canvas");
  canvas.width = images[0].width * images.length;
  canvas.height = images[0].height;

  let sliceX = images.length;
  let sliceY = 1;

  images.forEach((image, k) => {
    let height = 0;
    if (sliceY == 1 && k * image.width > 2048) {
      height = images[0].height;
      sliceX = k - 1;
      sliceY += 1;
      canvas.width = images[0].width * (k - 1);
      canvas.height += images[0].height;
    }
  });

  const ctx = canvas.getContext("2d");

  images.forEach((image, k) => {
    let height = 0;
    if (k * image.width > 2048) {
      height = images[0].height;
      k = k - sliceX - 1;
    }

    ctx.putImageData(image, k * image.width, height);
  });
  // Return Canvas as Data url
  return {
    url: canvas.toDataURL(),
    sliceX: sliceX,
    sliceY: sliceY,
    length: images.length,
  };
};

export function transparent() {
  const canvas = document.createElement("canvas");
  canvas.style.position = "absolute";
  canvas.width = 50;
  canvas.height = 50;

  return canvas.toDataURL();
}

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export async function preloadImages(sources, path) {
  return new Promise((resolve) => {
    const images = {};
    let loadedCounter = 0;
    sources.forEach((src) => {
      const image = new Image();
      // image.hidden = true;
      image.crossOrigin = "anonymous";
      image.id = src;

      image.onload = () => {
        images[src] = image;
        loadedCounter += 1;
        document.body.appendChild(image);
        image.style.height = "0px";
        if (loadedCounter === sources.length) {
          resolve(() => images);
        }
      };
      image.onerror = () => {
        console.error(src + " was not found");
        loadedCounter += 1;
        if (loadedCounter === sources.length) {
          resolve(() => images);
        }
      };
      image.src = path + "/" + src;
    });
  });
}

export function getValue(v, fallback) {
  if (!v) {
    if (fallback) return getValue(fallback);
    return undefined;
  }
  v = v.toString();
  if (v == "-") {
    if (fallback) return getValue(fallback);
    return undefined;
  }
  const split = v.split("-");

  if (split.length === 1) {
    return +split[0];
  }

  const min = +split[0];
  const max = +split[1];

  const theValue = Math.random() * (max - min) + min;

  return theValue;

  return;
}
