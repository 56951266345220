export function getSettingsFromURI(uri: string) {
  //   if (!uri) return undefined;
  uri = uri.replace("?", "");
  let settingString;
  const split = uri.split("&");
  for (let s of split) {
    if (s[0] === "s") {
      settingString = atob(s.split("=")[1]);
    }
  }

  const lines = settingString.split("\n");
  for (const line of lines) {
    const splitBy = line.split("=");
    if (splitBy[0] == "config") {
      const c = JSON.parse(splitBy[1]);
      return c;
    }
  }
  return undefined;
}
